<template>
  <v-container class="form__container banner rounded-xl" fluid>
    <v-row no-gutters class="banner__row">
      <v-col cols="12" md="6" sm="8">
        <v-container>
          <v-row>
            <v-col>
              <span
                class="text-h6 text-uppercase banner__new_feature_headline"
                >{{ title }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h4 white--text">{{ description }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                color="white"
                outlined
                depressed
                @click="
                  planBanner
                    ? $root.$emit('openPlanView', false)
                    : $router.push({ name: routeName })
                "
              >
                {{ buttonTitle }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Banner",
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    planBanner: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 930px) {
  .banner {
    background-position: right center;
  }
}

.banner {
  height: 100%;
  width: 100%;
  background-color: var(--v-cd_color_11-base);
  background-image: url("~@/assets/banner.jpg");
}

.banner__row {
  min-height: 300px;
  padding: 15px 15px 0px;
}

.banner__new_feature_headline {
  color: white;
}
</style>
