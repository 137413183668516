<template>
  <v-container v-if="!isSending" id="add_employee" tag="section" fluid>
    <v-card
      v-if="canAddEmployees"
      style="border-radius: 8px"
      elevation="3"
      active-class="b-radius"
    >
      <employee-full-form />
    </v-card>
    <banner
      v-else
      :title="$trans('employees_premium_banner_title')"
      :description="$trans('employees_premium_banner_description')"
      :button-title="$trans('change_plan_button_title')"
      plan-banner
    />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import EmployeeFullForm from "./components/EmployeeFullForm";
import Banner from "@/lib/calendesk-js-library/components/Banner";
import statsActions from "@/calendesk/mixins/statsActions";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "AddEmployee",
  components: { Banner, EmployeeFullForm },
  mixins: [statsActions, planActions],
  data() {
    return {
      statsMode: this.$helpers.statsMode.employees,
    };
  },
  created() {
    this.setSelectedEmployee(null);
    this.setSelectedUser(null);
  },
  methods: {
    ...mapMutations({
      setSelectedEmployee: "employee/SET_SELECTED_EMPLOYEE",
      setSelectedUser: "user/SET_SELECTED_USER",
    }),
  },
};
</script>
